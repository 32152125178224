<template>
    <div class="photoTypeList">
        <van-nav-bar
          title="证件照"
          left-arrow fixed
          @click-left="goBack"
          @click-right="isDropdownMenu= !isDropdownMenu"
        >
            <template #right>
                <van-icon size="22" name="apps-o"/>
            </template>
        </van-nav-bar>
        <div class="downMenuModal" v-if="isDropdownMenu" @click="isDropdownMenu = false">
            <div v-if="isDropdownMenu" class="dropdownMenu">
                <div class="menuItem" @click="$router.push('/')">
                    <span>首页</span>
                </div>
                <div class="menuItem" @click="$router.push('/xuanke')">
                    <span>选课中心</span>
                </div>
                <div class="menuItem" @click="$router.push('/tiku')">
                    <span>题库</span>
                </div>
                <div class="menuItem" @click="$router.push('/homeLiveList')">
                    <span>直播</span>
                </div>
                <div class="menuItem" @click="$router.push('/learning')">
                    <span>学习中心</span>
                </div>
                <div class="menuItem" @click="downApp">
                    <span>下载APP</span>
                </div>
            </div>
        </div>
        <div class="content-box">
            <div class="list-box">
                <div class="child-box" v-for="(item,index) in listData" @click="setPhotoDetail(item)" :style="index%2 == 0?'':'margin-left:25px'">
                    <div >{{item.title}}</div>
                   
                </div>

            </div>
            
        </div>
            


    </div>
</template>

<script>
import { getphotoList } from '@/api/photo.js'
export default {
        name: "photoTypeList",
        data() {
            return {
               listData:[],
               isDropdownMenu:false,
                
            }
        },
        mounted() {
            console.log("=========-----=====");
            //this.$toast.clear();
            this.$nextTick(()=>{
                this.init();
            })
            
        },

        methods: {
            getContainer() {
             return document.querySelector('.photoTypeList');
            },
            downApp(){
                window.location.href = 'https://www.saen.com/app/';
            },
            goBack() {
              this.$router.go(-1);
            },
            setRoute(val){
                if(val == 1){
                    
                }else{

                }
            },
            init(){
                this.$toast.loading({
                    message: '加载中...',
                   
                    duration:0,
                    
                });
                getphotoList().then((res)=>{
                    if(res.data.code == 0){
                        this.$toast.clear();
                        this.listData = res.data.data
                    }else{
                        this.$toast.clear();

                    }
                    
                })
            },
            setPhotoDetail(item){
                if(item.title == '自定义'){
                   //this.$toast('自定义功能暂未开放！');
                }else{
                    localStorage.setItem('shotTypeDetail',JSON.stringify(item));
                    this.$router.push({
                        path:'/shotPhoto'
                    })

                }
                

            },
            
            
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.photoTypeList{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
   
}
.downMenuModal{
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 99;

        .dropdownMenu{
            position: fixed;
            right: 10px;
            top:44px;
            background-color: #fff;
            z-index: 999;
            width: 100px;
            min-height: 200px;
            box-shadow: 10px 10px 10px 0px rgba(0,0,0,0.5);

            .menuItem{
                height: 40px;
                line-height: 40px;
                font-size: 13px;
                padding-left: 20px;
            }
            .menuItem5{
                color: #ef8f27;
            }
        }
    }
.content-box{
    width: 100%;
    height: calc(100vh - 46px);
    margin-top: 46px;
    .list-box{
        width: calc(100% -  60px);
        margin-left: 30px;
        //height: 100%;
        display: flex;
        flex-wrap: wrap;
        .child-box{
            margin-top: 20px;
            width: calc(50% - 15px);
            height: 35px;
            line-height: 35px;
            text-align: center;
            border: 1px solid #cccccc;
            box-shadow: 1px 1px 1px 1px #cccccc;
            border-radius: 4px;
        }
    }
    
}
</style>
